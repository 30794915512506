import { Image, Modal } from "antd";
import closeBtn from "@/assets/images/close-btn.webp";

import arrowLeftTitle from "@/assets/images/arrowleft-title.webp";
import decoLeftTitle from "@/assets/images/decoleft-title.webp";
import arrowRightTitle from "@/assets/images/arrowright-title.webp";
import decoRightTitle from "@/assets/images/decoright-title.webp";
import { useEffect } from "react";
function Popup({ open, setOpen, content, title }) {
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        const modalBody = document.querySelector(".ant-modal-body");
        if (modalBody) {
          modalBody.scrollTop = 0;
        } else {
          console.log("Modal body not found");
        }
      }, 0);

      return () => clearTimeout(timer);
    }
  }, [open, content]);

  return (
    <div className="relative">
      <Modal open={open} closeIcon={false} className="relative a">
        <>
          <div className="flex items-center justify-between bg-[#315023] py-2 shadow-sm border-header">
            <Image
              preview={false}
              src={decoLeftTitle}
              className="!max-w-[100%]"
            />
            <Image
              preview={false}
              src={arrowLeftTitle}
              className="!max-w-[100%]"
            />
            <h3 className="min-w-28 text-center gradient-text shadow-text font-bold text-[1.2rem] p-0 m-0 uppercase lg:text-[2.2rem]">
              {title}
            </h3>
            <Image
              preview={false}
              src={arrowRightTitle}
              className="!max-w-[100%]"
            />
            <Image
              preview={false}
              src={decoRightTitle}
              className="!max-w-[100%]"
            />
          </div>
          {content}
        </>
        <Image
          onClick={() => setOpen(false)}
          preview={false}
          src={closeBtn}
          className="fixed -bottom-12 left-1/2 -translate-x-1/2 z-[99999] !w-10 cursor-pointer closeBtn"
        />
      </Modal>
    </div>
  );
}

export default Popup;
