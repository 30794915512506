import { Image, Pagination, Tabs } from "antd";
import btnRegister from "@/assets/images/btn-register.webp";
import Link from "antd/es/typography/Link";
import { useState } from "react";

import logo from "@/assets/images/logo.webp";
import titleImg from "@/assets/images/title.webp";
import item1 from "@/assets/images/item1.webp";
import item2 from "@/assets/images/item2.webp";
import item3 from "@/assets/images/item3.webp";
import item4 from "@/assets/images/item4.webp";
import item5 from "@/assets/images/item5.webp";
import item6 from "@/assets/images/item6.webp";
import item7 from "@/assets/images/item7.webp";
import item8 from "@/assets/images/item8.webp";
import item9 from "@/assets/images/item9.webp";
import item10 from "@/assets/images/item10.webp";
import submit from "@/assets/images/submit.webp";
import Popup from "@/components/Popup";
import ModalPolicy from "@/components/ModalPolicy";

const itemAll = [
  {
    id: 1,
    image: item1,
    category: "common",
    title: "dịch vụ",
    content: (
      <div className="modal-body ">
        <div className="mt-1">
          <p className="text-white text-[0.9rem] lg:text-[1rem] p-2 pb-0 m-0">
            ★ Dịch vụ 1 ★
          </p>
          <div className="p-2 overflow-x-scroll">
            <table cellPadding={1} className="min-w-[200%] lg:min-w-full">
              <thead>
                <tr>
                  <th>Hoạt động</th>
                  <th>Tổng nạp trong tháng</th>
                  <th>Khách sạn quốc gia</th>
                  <th>Tiêu chuẩn khách sạn/ đêm</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={4}>Hội viên SVIP</td>
                  <td>≥ 500,000</td>
                  <td>1 đêm/ tháng</td>
                  <td rowSpan={4}>4,000,000 VND/Đêm</td>
                </tr>

                <tr>
                  <td>≥ 1,500,000</td>
                  <td>2 đêm/ tháng</td>
                </tr>

                <tr>
                  <td>≥ 2,500,000</td>
                  <td>3 đêm/ tháng</td>
                </tr>

                <tr>
                  <td>≥ 3,500,000</td>
                  <td>5 đêm/ tháng</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="text-white text-[0.9rem] lg:text-[1rem] pt-0 m-0 p-2">
            ※ Ví dụ: Hội viên nạp tích lũy 3,500,000 điểm trong tháng 5, sẽ được
            áp dụng trong tháng 6 là 5 đêm/tháng ở các khách sạn tiêu chuẩn lên
            đến 4,000,000 VND/Đêm.
          </p>
        </div>

        <div className="mt-2">
          <p className="text-white text-[0.9rem] lg:text-[1rem] p-2 pb-0 m-0">
            ★ Dịch vụ 2 ★
          </p>
          <div className="p-2 overflow-x-scroll">
            <table cellPadding={1} className="min-w-[200%] lg:min-w-full">
              <thead>
                <tr>
                  <th>Hoạt động</th>
                  <th>Tổng nạp trong tháng</th>
                  <th>Vé máy bay toàn cầu</th>
                  <th>Vé máy bay tiêu chuẩn/ đêm</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={4}>Hội viên SVIP</td>
                  <td>≥ 1,000,000</td>
                  <td>1 Vé/ tháng</td>
                  <td rowSpan={4}>5,000,000 VND/Vé</td>
                </tr>

                <tr>
                  <td>≥ 2,000,000</td>
                  <td>2 Vé/ tháng</td>
                </tr>

                <tr>
                  <td>≥ 4,000,000</td>
                  <td>3 Vé/ tháng</td>
                </tr>

                <tr>
                  <td>≥ 5,000,000</td>
                  <td>5 Vé/ tháng</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="text-white text-[0.9rem] lg:text-[1rem] pt-0 m-0 p-2">
            ※ Ví dụ: Thành viên nạp tích lũy 5,000,000 điểm trong tháng 6 có thể
            đăng ký 5 vé máy bay toàn cầu từ nhân viên dịch vụ khách hàng trong
            tháng 7. ※ Nữ thần áp dụng cho 5 vé máy bay tiêu chuẩn 5,000,000
            VND/Vé.
          </p>
        </div>

        <div className="mt-2">
          <p className="text-white text-[0.9rem] lg:text-[1rem] p-2 pb-0 m-0">
            ★ Dịch vụ 3 ★
          </p>
          <div className="p-2 overflow-x-scroll">
            <table cellPadding={1} className="min-w-[200%] lg:min-w-full">
              <thead>
                <tr>
                  <th>Hoạt động</th>
                  <th>Tổng nạp trong tháng</th>
                  <th>Loại dịch vụ/1 đêm</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan={4}>Hội viên SVIP</td>
                  <td>≥ 2,000,000</td>
                  <td>Người mẫu</td>
                </tr>

                <tr>
                  <td>≥ 3,000,000</td>
                  <td>Người mẫu cao cấp</td>
                </tr>

                <tr>
                  <td>≥ 5,000,000</td>
                  <td>Người mẫu hàng đầu</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="text-white text-[0.9rem] lg:text-[1rem] pt-0 m-0 p-2">
            ※ Ví dụ: Thành viên nạp tích lũy 5,000,000 điểm trong tháng 6, có
            thể đăng ký siêu mẫu hàng đầu từ nhân viên chăm sóc khách hàng trong
            tháng 7. <br />
            <br />※ Thành viên cần cung cấp thông tin nhận dạng cá nhân để đặt
            phòng khách sạn và vé máy bay.
            <br />
            <br /> ※ Các thành viên đáp ứng các yêu cầu có thể liên hệ với nữ
            thần độc quyền để đăng ký, điều này chỉ giới hạn cho chủ tài khoản
            đăng ký.
            <br />
            <br /> ※ Thành viên thỏa mãn điều kiện chỉ được chọn 1 trong số đó
            để nhận, không được nhận nhiều lần. <br />
            <br />※ Các hoạt động phải đăng ký trước 3 ngày để quý khách đi lại
            thuận tiện hơn. <br />
            <br />※ Nếu bạn đáp ứng các yêu cầu và không đến đăng ký nhận khuyến
            mãi hệ thống sẽ tự động hủy bỏ khuyến mãi, sẽ không đặt chỗ trước và
            không thể rút tiền mặt. <br />
            <br />※ Nếu phát hiện hành vi trục lợi OK9 có quyền hủy bỏ tư cách
            tham gia khuyến mãi này.
            <br />
            <br /> ※ OK9 bảo lưu quyền thay đổi, dừng hoặc hủy bỏ chương trình
            khuyến mãi này bất cứ lúc nào.
          </p>
        </div>

        <Link
          href=" https://bit.ly/ok94"
          target="_blank"
          className="flex justify-center"
        >
          <Image preview={false} src={submit} />
        </Link>
      </div>
    ),
  },
  {
    id: 2,
    image: item2,
    category: "common",
    title: "tặng mũ",
    content: (
      <div className="modal-body">
        <div className="mt-1">
          <p className="text-white text-[0.9rem] lg:text-[1rem] p-2 pb-0 m-0">
            ※ Chú ý: 1 Điểm = 1,000 VND. <br />
            <br /> ※ Múi giờ thống kê: GMT+8 <br />
            <br /> ※ Thời gian bắt đầu: 00:00:00 16-06-2024. <br />
            <br /> ※ Thời gian kết thúc: Cho tới khi có thông báo chính thức.{" "}
            <br />
            <br /> ※ Nội dung khuyến mãi: Tất cả các thành viên khi tham gia tại
            OK9 chỉ cần tham gia giao dịch thì sẽ được nhận ngay phần quà là
            Combo mũ bảo hiểm và bộ thể thao chất lượng cao. Ngoài ra còn rất
            nhiều phần quà và khuyến mãi HOT đang chờ đón bạn!
          </p>
          <div className="p-2 overflow-x-scroll">
            <table cellPadding={1} className="min-w-[200%] lg:min-w-full">
              <thead>
                <tr>
                  <th>Điểm nạp</th>
                  <th>Đối tượng</th>
                  <th>Phần thưởng</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1 +</td>
                  <td>Tất cả hội viên</td>
                  <td>Combo bộ thể thao và mũ bảo hiểm chất lượng cao</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="text-white text-[0.9rem] lg:text-[1rem] pt-0 m-0 p-2">
            ※Lưu ý : OK9 không thu bất kì tiền phí nào từ khách hàng khi nhận
            khuyến mãi này và chỉ hỗ trợ nhận khuyến mãi duy nhất tại Telegram :
            @QUATANGOK9
            <br />
            <br /> ※ Vật phẩm không thể quy đổi ra tiền mặt hoặc điểm số .
            <br />
            <br /> ※ Thành viên lạm dụng, vi phạm quy định công ty sẽ không được
            tham gia khuyến mãi này .
            <br />
            <br /> ※ Tham gia khuyến mãi có nghĩa là bạn đã đồng ý với "Quy Tắc
            Và Điều Khoản Khuyến Mãi"
          </p>
        </div>

        <Link
          href=" https://bit.ly/ok94"
          target="_blank"
          className="flex justify-center"
        >
          <Image preview={false} src={submit} />
        </Link>
      </div>
    ),
  },
  {
    id: 3,
    image: item3,
    category: "first",
    title: "Nạp đầu",
    content: (
      <div className="modal-body">
        <div className="mt-1">
          <p className="text-white text-[0.9rem] lg:text-[1rem] p-2 pb-0 m-0">
            ※ Chú ý: 1 Điểm = 1,000 VND.
            <br />
            <br />※ Múi giờ thống kê: GMT+8
            <br />
            <br />※ Thời gian bắt đầu: 15/08/2023
            <br />
            <br />※ Thời gian kết thúc: Cho đến khi có thông báo chính thức.
            <br />
            <br />※ Nội dung khuyến mãi: Tất cả thành viên đăng ký tài khoản lần
            đầu tiên tại OK9, sau khi nạp tiền thành công có thể nhận thưởng lên
            tới 18,888,000VND.
          </p>
          <div className="p-2 overflow-x-scroll">
            <table cellPadding={1} className="min-w-[100%]">
              <thead>
                <tr>
                  <th>Điều kiện nạp</th>
                  <th>Tiền thưởng </th>
                  <th>Vòng cược </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>≥ 100</td>
                  <td>38</td>
                  <td rowSpan={10}>3 vòng</td>
                </tr>
                <tr>
                  <td>≥ 500</td>
                  <td>128</td>
                </tr>
                <tr>
                  <td>≥ 1,000</td>
                  <td>188</td>
                </tr>
                <tr>
                  <td>≥ 3,000</td>
                  <td>388</td>
                </tr>
                <tr>
                  <td>≥ 5,000</td>
                  <td>588</td>
                </tr>
                <tr>
                  <td>≥ 12,000</td>
                  <td>1,288</td>
                </tr>
                <tr>
                  <td>≥ 20,000</td>
                  <td>1,888</td>
                </tr>
                <tr>
                  <td>≥ 50,000</td>
                  <td>3,888</td>
                </tr>
                <tr>
                  <td>≥ 150,000</td>
                  <td>8,888</td>
                </tr>
                <tr>
                  <td>≥ 350,000</td>
                  <td>18,888</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="text-white text-[0.9rem] lg:text-[1rem] pt-0 m-0 p-2">
            ※ Phương thức nhận thưởng: Sau khi nạp điểm thành công, thành viên
            nhận khuyến mãi vui lòng điền đầy đủ thông tin ngân hàng trước, sau
            đó đọc kỹ nội dung khuyến mãi và nhấn vào Áp dụng ngay bây giờ.
            <br />
            <br />※ Khuyến mãi áp dụng trong vòng 72h kể từ thời gian nạp đầu
            tiên. Qua hạn xem như từ bỏ khuyến mãi ,không thể đăng ký.
            <br />
            <br />※ Khuyến mãi này không áp dụng cho trò chơi xổ số và không
            song hành cùng những khuyến mãi nạp đầu khác.
            <br />
            <br />※ Tiền thưởng khuyến mãi thành viên cần trải qua 3 vòng cược
            để đủ điều kiện rút tiền.
            <br />
            <br />※ Sau khi hoàn thành lần nạp tiền đầu tiên, thành viên vui
            lòng không đặt cược trước khi đăng ký khuyến mãi này. Nếu đã đặt
            cược sẽ được xem là không phù hợp yêu cầu khuyến mãi .
            <br />
            <br />※ Ví dụ: Thành viên gửi 100 điểm + 38 điểm thường = Tổng cược
            yêu cầu: (100 + 38 ) x 3 = 414 điểm.
            <br />
            <br />※ Thành viên cần điền đúng thông tin ngân hàng và không được
            thay đổi trong lần rút tiền đầu tiên, nếu thông tin sai lệch sẽ bị
            hệ thống khấu trừ toàn bộ tiền khuyến mãi và tiền thắng phát sinh.
            <br />
            <br />※ Điều kiện nhận khuyến mãi này do OK9 xét duyệt và OK9 bảo
            lưu quyền quyết định cuối cùng trong bất kỳ trường hợp nào.
            <br />
            <br />※ Thành viên lạm dụng, vi phạm quy định công ty sẽ không được
            tham gia khuyến mãi này.
            <br />
            <br />※ Tham gia khuyến mãi có nghĩa là bạn đã đồng ý với "Quy Tắc
            Và Điều Khoản Khuyến Mãi"
          </p>
        </div>

        <Link
          href=" https://bit.ly/ok94"
          target="_blank"
          className="flex justify-center"
        >
          <Image preview={false} src={submit} />
        </Link>
      </div>
    ),
  },
  {
    id: 4,
    image: item4,
    category: "invite",
    title: "giới thiệu",
    content: (
      <div className="modal-body">
        <div className="mt-1">
          <p className="text-white text-[0.9rem] lg:text-[1rem] p-2 pb-0 m-0">
            ※ Chú ý : 1 Điểm = 1,000 VND <br />
            <br />※ Múi giờ thống kê: GMT+8. <br />
            <br />※ Thời gian bắt đầu: 00:00 ngày 10/04/2024. <br />
            <br />※ Thời gian kết thúc: Cho đến khi có thông báo chính thức.{" "}
            <br />
            <br />※ Nội dung khuyến mãi: Mỗi thành viên của OK9 sẽ có một mã
            giới thiệu và liên kết riêng. Chỉ cần bạn chia sẻ mã giới thiệu hoặc
            liên kết cho bạn bè, người thân cùng tham gia sẽ có thể nhận được
            hoa hồng lên tới 1% số tiền nạp đầu của người được giới thiệu, đồng
            thời bạn bè được giới thiệu cũng nhận được phần thưởng, chi tiết vui
            lòng tham khảo bảng sau:
          </p>
          <div className="p-2 overflow-x-scroll">
            <table cellPadding={1} className="min-w-[100%]">
              <thead>
                <tr>
                  <th>Tiền nạp đầu</th>
                  <th>Tiền thưởng người giới thiệu</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>≥ 100</td>
                  <td>Nạp đầu x 1% </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="p-2 overflow-x-scroll">
            <table cellPadding={1} className="min-w-[100%]">
              <thead>
                <tr>
                  <th>Tiền nạp đầu</th>
                  <th>Tiền thưởng người được giới thiệu</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>≥ 300</td>
                  <td>3 </td>
                </tr>
                <tr>
                  <td>≥ 800</td>
                  <td>8 </td>
                </tr>
                <tr>
                  <td>≥ 2,000</td>
                  <td>18 </td>
                </tr>
                <tr>
                  <td>≥ 3,000</td>
                  <td>28 </td>
                </tr>
                <tr>
                  <td>≥ 5,000</td>
                  <td>58 </td>
                </tr>{" "}
                <tr>
                  <td>≥ 12,000</td>
                  <td>128 </td>
                </tr>{" "}
                <tr>
                  <td>≥ 20,000</td>
                  <td>188 </td>
                </tr>
                <tr>
                  <td>≥ 50,000</td>
                  <td>588 </td>
                </tr>
                <tr>
                  <td>≥ 150,000</td>
                  <td>1,588 </td>
                </tr>
                <tr>
                  <td>≥ 350,000</td>
                  <td>3,888 </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="text-white text-[0.9rem] lg:text-[1rem] pt-0 m-0 p-2">
            ※ Tiền thưởng không cần trải qua vòng cược có thể rút tiền ngay sau
            khi nhận thưởng.
            <br />
            <br />※ Ngay sau khi người được giới thiệu tiến hành nạp đầu, hệ
            thống sẽ tự động phát thưởng tiền thưởng cho người giới thiệu và
            người được giới thiệu.
            <br />
            <br />※ Thành viên không được phép tạo tài khoản của mình dưới link
            giới thiệu của chính mình, nếu phát hiện hệ thống sẽ loại bỏ tài
            khoản được giới thiệu ra khỏi danh sách được giới thiệu.
            <br />
            <br />※ Nếu phát hiện hành vị trục lợi tiền thưởng giới thiệu, hệ
            thống có quyền thu hồi tiền thưởng và tiền thắng phát sinh.
            <br />
            <br />※ Tham gia khuyến mãi có nghĩa bạn đồng ý với "Quy tắc và Điều
            khoản của khuyến mãi".
          </p>
        </div>

        <Link
          href=" https://bit.ly/ok94"
          target="_blank"
          className="flex justify-center"
        >
          <Image preview={false} src={submit} />
        </Link>
      </div>
    ),
  },
  {
    id: 5,
    image: item5,
    category: "daily",
    title: "điểm danh",
    content: (
      <div className="modal-body">
        <div className="mt-1">
          <p className="text-white text-[0.9rem] lg:text-[1rem] p-2 pb-0 m-0">
            Chú ý: 1 Điểm = 1,000 VND.
            <br />
            <br />※ Múi giờ thống kê: GMT+8.
            <br />
            <br />※ Thời gian bắt đầu: 00:00 ngày 10/04/2024.
            <br />
            <br />※ Thời gian kết thúc: Cho đến khi có thông báo chính thức.
            <br />
            <br />※ Nội dung chi tiết: Thành viên có tham gia nạp tiền tại OK9,
            tiến hành đặt cược mỗi ngày sẽ có thể tham gia hoạt động Điểm danh
            nhận thưởng mỗi ngày. Nếu liên tục hoàn thành điểm danh 7 ngày thì
            sẽ được nhận thêm tiền thưởng điểm danh liên tục.
            <br />
            <br />※ Khuyến mãi chỉ yêu cầu hoàn thành 1 vòng cược là có thể rút
            tiền.
            <br />
            <br />※ Mỗi 1 thẻ ngân hàng, địa chỉ IP, thiết bị, họ và tên chỉ
            được tham gia với 1 tài khoản duy nhất.
            <br />
            <br />※ Thành viên lạm dụng, vi phạm quy định công ty sẽ không được
            tham gia khuyến mãi này.
            <br />
            <br />※ Tham gia khuyến mãi có nghĩa là bạn đồng ý với "Quy tắc và
            Điều khoản khuyến mãi".
          </p>
        </div>

        <Link
          href=" https://bit.ly/ok94"
          target="_blank"
          className="flex justify-center"
        >
          <Image preview={false} src={submit} />
        </Link>
      </div>
    ),
  },
  {
    id: 6,
    image: item6,
    category: "gift",
    title: "Tặng 5%",
    content: (
      <div className="modal-body">
        <div className="mt-1">
          <p className="text-white text-[0.9rem] lg:text-[1rem] p-2 pb-0 m-0">
            Chú ý: 1 Điểm = 1,000 VND. <br />
            <br />※ Múi giờ thống kê: GMT+8 <br />
            <br />※ Thời gian bắt đầu: 00:00 ngày 10/04/2024 <br />
            <br />※ Thời gian kết thúc: Cho đến khi có thông báo chính thức.{" "}
            <br />
            <br />※ Nội dung chi tiết: Tất cả thành viên OK9 mỗi thứ bảy, chủ
            nhật hàng tuần nạp tiền và tham gia cược sản phẩm Thể Thao - Esport
            sẽ được tặng thưởng 5% tiền nạp, mỗi ngày một lần, chỉ cần hoàn
            thành 1 vòng cược là có thể rút tiền, không giới hạn thành viên tham
            gia.
          </p>
          <div className="p-2 overflow-x-scroll">
            <table cellPadding={1} className="min-w-[100%]">
              <thead>
                <tr>
                  <th>Tiền nạp tối thiểu</th>
                  <th>Tiền thưởng </th>
                  <th>Vòng cược </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>≥ 100</td>
                  <td>5% đơn nạp </td>
                  <td>1 vòng </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="text-white text-[0.9rem] lg:text-[1rem] pt-0 m-0 p-2">
            ※ Lưu ý: Áp dụng mỗi Thứ bảy và Chủ nhật hàng tuần, qua ngày không
            đủ điều kiện đăng ký. <br />
            <br />※ Khuyến mãi này chỉ áp dụng với sản phẩm Thể thao - Esport.{" "}
            <br />
            <br />※ Tiền khuyến mãi dựa vào số tiền nạp gần nhất của quý khách
            trong ngày và chưa tiến hành đặt cược. Nếu đã tham gia đặt cược thì
            đơn nạp này sẽ không phù hợp để đăng ký khuyến mãi. <br />
            <br />※ Thành viên lạm dụng vi phạm quy định công ty sẽ không được
            tham gia khuyến mãi này. <br />
            <br />※ Tham gia khuyến mãi có nghĩa là bạn đồng ý với "Quy tắc và
            điều khoản khuyến mãi".
          </p>
        </div>

        <Link
          href=" https://bit.ly/ok94"
          target="_blank"
          className="flex justify-center"
        >
          <Image preview={false} src={submit} />
        </Link>
      </div>
    ),
  },
  {
    id: 7,
    image: item7,
    category: "gift",
    title: "thưởng 0.6%",
    content: (
      <div className="modal-body">
        <div className="mt-1">
          <p className="text-white text-[0.9rem] lg:text-[1rem] p-2 pb-0 m-0">
            Chú ý: 1 Điểm = 1,000 VND.
            <br />
            <br />※ Múi giờ thống kê: GMT+8.
            <br />
            <br />※ Thời gian bắt đầu: 00:00 ngày 10/04/2024.
            <br />
            <br />※ Thời gian kết thúc: Cho đến khi có thông báo chính thức.
            <br />
            <br />※ Nội dung chi tiết: Thành viên khi nạp tiền từ 10 điểm tại
            OK9 sẽ được thưởng 0.6% mỗi lần nạp không giới hạn số lần, không
            giới hạn số tiền.
          </p>
          <div className="p-2 overflow-x-scroll">
            <table cellPadding={1} className="min-w-[100%]">
              <thead>
                <tr>
                  <th>Điều kiện nạp</th>
                  <th>Tiền thưởng </th>
                  <th>Vòng cược </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>≥ 10</td>
                  <td>0.6 % </td>
                  <td>1 </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="text-white text-[0.9rem] lg:text-[1rem] pt-0 m-0 p-2">
            ※ Thành viên cần hoàn thành: Tổng điểm cược = [(điểm gửi tiền + điểm
            khuyến mãi) x 1 lần] là có thể rút tiền bất cứ lúc nào.
            <br />
            <br />※ Ví dụ: Nạp 10,000 điểm, số điểm khuyến mãi: 10,000*0.6% = 60
            điểm, số doanh thu hợp lệ yêu cầu là (10,000+60)*1 = 10,060 điểm.
            <br />
            <br />※ Thành viên lạm dụng, vi phạm quy định công ty sẽ không được
            tham gia khuyến mãi này.
            <br />
            <br />※ Tham gia khuyến mãi có nghĩa là bạn đồng ý với "Quy Tắc Và
            Điều Khoản Khuyến Mãi".
          </p>
        </div>

        <Link
          href=" https://bit.ly/ok94"
          target="_blank"
          className="flex justify-center"
        >
          <Image preview={false} src={submit} />
        </Link>
      </div>
    ),
  },
  {
    id: 8,
    image: item8,
    category: "common",
    title: "hoàn trả",
    content: (
      <div className="modal-body">
        <div className="mt-1">
          <p className="text-white text-[0.9rem] lg:text-[1rem] p-2 pb-0 m-0">
            ※ Chú ý: 1 Điểm = 1,000 VND. <br />
            <br /> ※ Múi giờ thống kê: GMT+8 <br />
            <br /> ※ Nội dung khuyến mãi: Thành viên OK9 khi tham gia đặt cược
            tại tất cả các trò chơi không kể thắng hay thua, chỉ với 1 điểm trở
            lên bạn có thể nhận được ưu đãi điểm hoàn trả không giới hạn theo tỷ
            lệ %, cược càng nhiều hoàn trả càng cao. <br />
            <br /> ※ Đặc biệt OK9 ra mắt hoàn trả tức thời , thành viên có thể
            thanh toán tiền hoàn trả trò chơi ngay lập tức theo nhu cầu của mình
            và hệ thống sẽ tự động gửi tiền hoàn trả vào tài khoản thành viên
            trong vòng 1 phút .
          </p>
          <div className="p-2 overflow-x-scroll">
            <table cellPadding={1} className="min-w-[200%] lg:min-w-full">
              <thead>
                <tr>
                  <th>Cược hợp lệ</th>
                  <th>Thể thao-Esport</th>
                  <th>Casino</th>
                  <th>Nổ hũ</th>
                  <th>Bắn cá</th>
                  <th>Game bài</th>
                  <th>Đá gà</th>
                  <th>Xổ số</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>≥ 1</td>
                  <td>0.70%</td>
                  <td>0.40%</td>
                  <td>1.20%</td>
                  <td>1.20%</td>
                  <td>0.80%</td>
                  <td>0.60%</td>
                  <td rowSpan={6}> 0.20%</td>
                </tr>

                <tr>
                  <td>≥ 1,000,000</td>
                  <td>0.80%</td>
                  <td>0.45%</td>
                  <td>1.40%</td>
                  <td>1.40%</td>
                  <td>1.00%</td>
                  <td>0.70%</td>
                </tr>

                <tr>
                  <td>≥ 5,000,000</td>
                  <td>0.90%</td>
                  <td>0.50%</td>
                  <td>1.50%</td>
                  <td>1.50%</td>
                  <td>1.10%</td>
                  <td>0.80%</td>
                </tr>

                <tr>
                  <td>≥ 10,000,000</td>
                  <td>1.10%</td>
                  <td>0.60%</td>
                  <td>1.60%</td>
                  <td>1.60%</td>
                  <td>1.20%</td>
                  <td>0.90%</td>
                </tr>

                <tr>
                  <td>≥ 50,000,000</td>
                  <td>1.30%</td>
                  <td>0.70%</td>
                  <td>1.80%</td>
                  <td>1.80%</td>
                  <td>1.30%</td>
                  <td>1.20%</td>
                </tr>

                <tr>
                  <td>≥ 100,000,000</td>
                  <td>1.60%</td>
                  <td>0.80%</td>
                  <td>2.00%</td>
                  <td>2.00%</td>
                  <td>1.50%</td>
                  <td>1.50%</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="text-white text-[0.9rem] lg:text-[1rem] pt-0 m-0 p-2">
            ※ Hoàn trả sẽ được gửi đồng nhất vào tài khoản của thành viên vào
            1h00 ngày hôm sau.
            <br />
            <br /> ※ Tiền thưởng nhận được không cần trải qua vòng cược, có thể
            rút tiền ngay.
            <br />
            <br /> ※ Hoàn trả sẽ không áp dụng đối với các trường hợp vi phạm,
            vé cược bị huỷ, vé cược hoà, người chơi có hành vi cược đối đầu, vi
            phạm các quy định của trò chơi.
            <br />
            <br /> ※ Khuyến mãi tính theo thời gian thanh toán vé cược theo múi
            giờ GMT+8.
            <br />
            <br /> ※ OK9 bảo lưu quyền thay đổi, dừng hoặc hủy bỏ chương trình
            khuyến mãi này bất cứ lúc nào.
            <br />
            <br /> ※ Tham gia khuyến mãi có nghĩa là bạn đã đồng ý với "Quy Tắc
            Và Điều Khoản Khuyến Mãi"
          </p>
        </div>

        <Link
          href=" https://bit.ly/ok94"
          target="_blank"
          className="flex justify-center"
        >
          <Image preview={false} src={submit} />
        </Link>
      </div>
    ),
  },
  {
    id: 9,
    image: item9,
    category: "common",
    title: "Ý tưởng",
    content: (
      <div className="modal-body">
        <div className="mt-1">
          <p className="text-white text-[0.9rem] lg:text-[1rem] p-2 pb-0 m-0">
            ※ Chú ý: 1 Điểm = 1,000 VND.
            <br />
            <br />※ Nội dung khuyến mãi: Chúng Tôi mong muốn lắng nghe các phản
            hồi, góp ý của toàn thể quý hội viên, đó là tài sản quý giá để OK9
            nổ lực không ngừng hoàn thiện chất lượng dịch vụ đem tới cho khách
            hàng nền tảng giải trí hoàn hảo nhất. OK9 luôn đặt lợi ích của khách
            hàng lên hàng đầu và coi sự hài lòng của khách hàng là thước đo
            thành công.
            <br />
            <br />
            ※Phương thức đóng góp ý kiến: Hội viên vui lòng gửi email góp ý có
            đầy đủ thông tin tên đăng nhập của mình tới địa chỉ: admin@ok9.com.
            <br />
            <br />※ Phương thức phát thưởng: <br />
            <br /> - Vào trước 17:00 ngày 01 và ngày 15 hàng tháng, OK9 sẽ tổng
            hợp thu thập những ý kiến đóng góp của quý hội viên và lựa chọn 100
            hội viên góp ý phù hợp với định hướng phát triển thương hiệu của hệ
            thống phát thưởng 1000 điểm (1 triệu đồng) trực tiếp vào tài khoản
            game. <br />
            <br /> - Và hơn thế nữa, hệ thống sẽ dành tặng những phần thưởng bí
            ẩn tri ân để cảm ơn những quý hội viên đã luôn dành trọn niềm tin và
            tin tưởng đồng hành đóng góp ý kiến dù cho ý kiến đó hiện tại chưa
            phù hợp với định hướng chung OK9 hướng tới, danh sách phát thưởng bí
            ẩn may mắn là ngẫu nhiên lựa chọn. <br />
            <br /> - Để đảm bảo tính an toàn bảo mật cho tài khoản của Quý hội
            viên, danh sách thành viên trúng thưởng không công bố công khai ở
            trang chủ mà sẽ do OK9 lựa chọn và gửi thông báo tới từng thành viên
            trúng thưởng. <br />
            <br /> - Lắng nghe để phát triển, trân trọng từng ý kiến của hội
            viên, OK9 đã và đang tiếp tục khẳng định vị thế là một nhà cái dẫn
            đầu trong lĩnh vực giải trí trên thị trường hiện nay và sẽ cố gắng
            xây dựng phát triển vững mạnh với chất lượng sản phẩm và dịch vụ
            ngày một tốt hơn để đền đáp sự ủng hộ nhiệt tình quý hội viên.
            <br />
            <br />※ Chi tiết hoạt động: <br />
            <br /> - Khuyến mãi tính theo múi giờ GMT+8. <br />
            <br /> - Tiền thưởng nhận được không cần vòng cược, trực tiếp rút
            tiền. Hội viên có nhiều tài khoản chỉ được tham gia nhận thưởng 1
            tài khoản duy nhất. <br />
            <br /> - OK9 bảo lưu quyền thay đổi, dừng hoặc hủy bỏ chương trình
            khuyến mãi này bất cứ lúc nào. <br />
            <br /> - Tham gia khuyến mãi có nghĩa là bạn đã đồng ý với “Quy tắc
            và điều khoản khuyến mãi”.
          </p>
        </div>

        <Link
          href=" https://bit.ly/ok94"
          target="_blank"
          className="flex justify-center"
        >
          <Image preview={false} src={submit} />
        </Link>
      </div>
    ),
  },
  {
    id: 10,
    image: item10,
    category: "common",
    title: "quy tắc",
    content: (
      <div className="modal-body">
        <div className="mt-1">
          <p className="text-white text-[0.9rem] lg:text-[1rem] p-2 pb-0 m-0">
            1. Tất cả khuyến mãi dựa trên đơn vị tính: 1 Điểm = 1,000 VND. Thời
            gian tính dựa theo múi giờ GMT+8.
            <br />
            <br /> 2. Mỗi người chơi, mỗi địa chỉ cư trú, mỗi địa chỉ email, mỗi
            số điện thoại, cùng một phương thức thanh toán (cùng thẻ ghi nợ/ thẻ
            tín dụng/ tài khoản ngân hàng) và địa chỉ IP chỉ được tham gia một
            lần khuyến mãi. Nếu thành viên cố tình tạo nhiều tài khoản lạm dụng
            khuyến mãi, OK9 có quyền huỷ bỏ hoặc thu hồi số tiền thưởng và lợi
            nhuận của thành viên.
            <br />
            <br /> 3. Tất cả các chương trình khuyến mãi tại OK9 đều dựa trên
            nhu cầu thực tế của người chơi. Nếu bất kỳ nhóm hoặc cá nhân nào có
            hành vi lạm dụng hoặc không trung thực khi tham gia khuyến mãi , lạm
            dụng khuyến mãi từ OK9, OK9 có quyền huỷ bỏ tài khoản nhóm hoặc cá
            nhân và đóng băng số dư tài khoản khi vi phạm.
            <br />
            <br /> 4. Nếu thành viên có tranh chấp về sự kiện khuyến mãi, để đảm
            bảo quyền lợi của hai bên và tránh ảnh hưởng uy tín, OK9 có quyền
            yêu cầu thành viên cung cấp cho chúng tôi các tài liệu liên quan,
            hình ảnh trong các văn bản xác định (khuôn mặt của khách hàng phải
            được nhìn thấy rõ trong ảnh), việc không xuất trình các thông tin
            khi có yêu cầu có thể dẫn đến việc các khoản tiền thưởng khuyến mãi
            và tiền thắng cược sẽ bị thu hồi.
            <br />
            <br /> 5. Khi thành viên tham gia không tuân thủ đầy đủ hoặc vi phạm
            quy định, lạm dụng bất kỳ điều khoản nào về chiết khấu hoặc khuyến
            mãi của OK9 , hoặc OK9 phát hiện bất kỳ cá nhân hoặc nhóm thành viên
            nào có hành vi cược bất thường liên quan, tiền thưởng khi gửi tiền
            hoặc các hoạt động khuyến mãi khác mang lại lợi nhuận , OK9 có quyền
            dừng, hủy bỏ khuyến mãi hoặc thu hồi lại tất cả tiền thưởng đã được
            kết toán cho đội hoặc cá nhân này. Đối với những trường hợp nghiêm
            trọng, OK9 sẽ đóng băng tài khoản vĩnh viễn mà không cần thông báo
            hoặc giải thích. Ngoài ra, Giải trí OK9 cũng có quyền trích các
            khoản chi phí quản lý tương đương với giá trị tiền thưởng khuyến mãi
            từ những khách hàng này để bù đắp cho chi phí quản lý của chúng tôi.
            Việc xác định như thế nào là lạm dụng tiền thưởng sẽ dựa trên việc
            kiểm tra chặt chẽ những bằng chứng rõ ràng về lạm dụng và gian lận
            tiền thưởng như sau:
            <br />
            <br /> + Cược 2 bên (cược đối nghịch). Ví dụ: Trong Baccarat cùng
            lúc cược nhà cái và nhà con. Trong Roulette cược cùng lúc đỏ và đen…
            <br />
            <br /> + Gian lận có tổ chức, theo nhóm, sử dụng phần mềm đặt
            cược...
            <br />
            <br /> + Đặt cược tiền thưởng vào các trò chơi bị loại trừ.
            <br />
            <br /> + Đặt cược gian lận doanh thu cược.
            <br />
            <br /> + Một thành viên sử dụng nhiều tài khoản để tham gia cược.
            <br />
            <br /> + Cược chéo tài khoản, cùng hoặc khác hệ thống.
            <br />
            <br /> + Lạm dụng chênh lệch tỷ lệ kèo giữa các trang cá cược .
            <br />
            <br /> + Tất cả khuyến mãi đều không áp dụng đối với sản phẩm Xổ Số.
            <br />
            <br /> + Tiền thưởng chỉ được nhận một lần duy nhất (ví dụ Khuyến
            mãi dành cho thành viên mới). OK9 có quyền chỉnh sửa, thay đổi hoặc
            hủy bỏ chương trình Khuyến mãi hoặc một phần của chương trình bất kỳ
            thời điểm nào mà không cần báo trước.
            <br />
            <br /> + Xổ số 2D cược tối đa 70 số, 3D cược tối đa 600 số, 4D cược
            tối đa 5500 số, thành viên cược quá số quy định hệ thống sẽ thu hồi
            tiền thắng dựa trên các vé cược sai quy định.
            <br />
            <br /> + Cược trong trò Roulette chỉ được phép cược tối đa 30 số,
            cược quá số quy định hệ thống sẽ thu hồi tiền thắng cược (nếu có).
            <br />
            <br /> 6. OK9 có quyền hạn chế hoặc tạm thời hạn chế bất kỳ thành
            viên tham gia Khuyến mãi nào khi phát hiện có dấu hiệu lạm dụng
            khuyến mãi. Trong trường hợp thành viên có hành vi vi phạm các điều
            kiện và điều khoản, quyết định của OK9 là quyết định cuối cùng và
            thành viên vi phạm không có quyền Khiếu Nại.
            <br />
            <br /> 7. Đối với các khuyến mãi hoàn trả, tất cả các cược Hòa, cược
            Hủy, cược 2 bên đều không được tính vào doanh thu cược để tính hoàn
            trả, chơi trò chơi 2 mặt trong sảnh slot sẽ không được tính hoàn trả
            (Trò chơi 2 mặt được hiển thị và hoạt động tại sảnh Slot nhưng hình
            thức tham gia lại mang tính chất casino. Ví dụ : Sicbo, Tài Xỉu Kiểu
            Thái, Xóc Đĩa …)
            <br />
            <br /> 8. Tiền thưởng khuyến mãi có giới hạn cược ở từng loại sản
            phẩm không được tham gia cược ở sản phẩn khác trước khi Thành viên
            hoàn thành số vòng cược yêu cầu tại khuyến mãi đó. Tiền thưởng
            khuyến mãi có quy định thời gian nhận thưởng, nếu quá thời gian nhận
            thưởng Thành viên không đăng ký đồng nghĩa với việc từ chối khuyến
            mãi. Phần thưởng của bất kỳ khuyến mãi nào nếu không hoàn thành đúng
            quy định khuyến mãi hệ thống sẽ thu hồi tiền khuyến mãi và tiền
            thắng (nếu có).
            <br />
            <br /> 9. Tất cả thành viên tham gia các chương trình khuyến mãi
            phải tuân thủ các Quy Tắc & Điều Kiện của OK9 và Các Quy Định Luật
            Lệ chung của OK9 .
            <br />
            <br /> 10. OK9 có quyền quyết định cuối cùng về sự kiện và quyền sửa
            đổi và chấm dứt sự kiện mà không cần thông báo, áp dụng cho tất cả
            các ưu đãi khuyến mãi.
          </p>
        </div>

        <Link
          href=" https://bit.ly/ok94"
          target="_blank"
          className="flex justify-center"
        >
          <Image preview={false} src={submit} />
        </Link>
      </div>
    ),
  },
];

const itemsPerPage = 10;

function Home() {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPagSize] = useState();

  const onChange = (key) => {
    setCurrentPagSize(key);
    setCurrentPage(currentPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const offset = (currentPage - 1) * itemsPerPage;
  const currentItems = itemAll.slice(offset, offset + itemsPerPage);
  const currentGiftItems = itemAll
    .filter((item) => item.category === "gift")
    .slice(offset, offset + itemsPerPage);
  const currentCommonItems = itemAll
    .filter((item) => item.category === "common")
    .slice(offset, offset + itemsPerPage);
  const currentFirstItems = itemAll
    .filter((item) => item.category === "first")
    .slice(offset, offset + itemsPerPage);
  const currentInviteItems = itemAll
    .filter((item) => item.category === "invite")
    .slice(offset, offset + itemsPerPage);
  const currentDailyItems = itemAll
    .filter((item) => item.category === "daily")
    .slice(offset, offset + itemsPerPage);

  const [open, setOpen] = useState(false);
  const [content, setContent] = useState(false);
  const [title, setTitle] = useState();

  const handOnClick = (item) => {
    setOpen(true);
    setContent(item.content);
    setTitle(item.title);
  };

  const items = [
    {
      key: "1",
      label: "Tất cả khuyến mãi",
      children: (
        <div className="grid lg:grid-cols-2 lg:gap-x-3">
          {currentItems.map((item, index) => (
            <Image
              preview={false}
              src={item.image}
              width="100%"
              key={index}
              className="mb-3 cursor-pointer"
              onClick={() => handOnClick(item)}
            />
          ))}
        </div>
      ),
    },
    {
      key: "2",
      label: "Khuyến mãi chung",
      children: (
        <div className="grid lg:grid-cols-2 lg:gap-x-3">
          {currentCommonItems.map((item, index) => (
            <Image
              preview={false}
              src={item.image}
              width="100%"
              key={index}
              className="mb-3 cursor-pointer"
              onClick={() => handOnClick(item)}
            />
          ))}
        </div>
      ),
    },
    {
      key: "3",
      label: "Khuyến mãi nạp đầu",
      children: (
        <div className="grid lg:grid-cols-2 lg:gap-x-3">
          {currentFirstItems.map((item, index) => (
            <Image
              preview={false}
              src={item.image}
              width="100%"
              key={index}
              onClick={() => handOnClick(item)}
              className="mb-3 cursor-pointer"
            />
          ))}
        </div>
      ),
    },

    {
      key: "4",
      label: "Giới thiệu bạn bè",
      children: (
        <div className="grid lg:grid-cols-2 lg:gap-x-3">
          {currentInviteItems.map((item, index) => (
            <Image
              preview={false}
              src={item.image}
              width="100%"
              key={index}
              onClick={() => handOnClick(item)}
              className="mb-3 cursor-pointer"
            />
          ))}
        </div>
      ),
    },

    {
      key: "5",
      label: "Điểm danh hàng ngày",
      children: (
        <div className="grid lg:grid-cols-2 lg:gap-x-3">
          {currentDailyItems.map((item, index) => (
            <Image
              preview={false}
              src={item.image}
              width="100%"
              key={index}
              onClick={() => handOnClick(item)}
              className="mb-3 cursor-pointer"
            />
          ))}
        </div>
      ),
    },
    {
      key: "6",
      label: "Nạp là tặng",
      children: (
        <div className="grid lg:grid-cols-2 lg:gap-x-3">
          {currentGiftItems.map((item, index) => (
            <Image
              preview={false}
              src={item.image}
              width="100%"
              key={index}
              onClick={() => handOnClick(item)}
              className="mb-3 cursor-pointer"
            />
          ))}
        </div>
      ),
    },

    {
      key: "7",
      label: "",
    },
  ];

  return (
    <>
      <ModalPolicy />
      <div className="w-[95%] lg:w-[70%] mx-auto text-center pb-5">
        <Image preview={false} src={logo} className="!w-[70%]" />
        <Image preview={false} src={titleImg} width="100%" className="mt-5" />
        <Link
          href=" https://bit.ly/ok94"
          target="_blank"
          className="inline-flex justify-center btn-register"
        >
          <Image
            preview={false}
            src={btnRegister}
            className="animate-scale my-5 mb-10 !w-[60%] lg:!w-[35%]"
          />
        </Link>
        <Tabs
          items={items}
          onChange={onChange}
          className="lg:flex lg:justify-center"
        />
        <div className="pagination-custom">
          <Pagination
            current={currentPage}
            total={
              currentPageSize === "1"
                ? itemAll.length
                : currentPageSize === "2"
                ? currentCommonItems.length
                : currentPageSize === "3"
                ? currentFirstItems.length
                : currentPageSize === "4"
                ? currentInviteItems.length
                : currentPageSize === "5"
                ? currentDailyItems.length
                : currentGiftItems.length
            }
            pageSize={itemsPerPage}
            onChange={handlePageChange}
            className="flex justify-center lg:w-[40%] lg:mt-5"
          />
        </div>

        <Popup open={open} setOpen={setOpen} content={content} title={title} />
      </div>
    </>
  );
}

export default Home;
