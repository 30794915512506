import { Modal } from "antd";
import { useState } from "react";

function ModalPolicy() {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const data = [
    {
      id: 1,
      content:
        "Bất kỳ tiền thưởng và thắng cược không được chuyển nhượng cho các tài khoản khác [lưu trong quyết định riêng của công ty]. Các giải thưởng của bất kỳ tiền thưởng và thắng cược, tùy thuộc vào quyết định riêng của công ty.",
    },
    {
      id: 2,
      content:
        "Theo quyết định của công ty, bạn có thể yêu cầu công ty cung cấp thông tin nhận dạng. Đó là bắt buộc bạn cung cấp các thông tin yêu cầu khi thông báo của chúng tôi. Nếu bạn không cung cấp thông tin được yêu cầu,có nghĩa bạn đồng ý từ bỏ quyền lợi của bạn đến bất kỳ số dư còn lại trong tài khoản OK9 của bạn.",
    },
    {
      id: 3,
      content:
        "Bạn phải có ít nhất 18 tuổi hoặc trên độ tuổi của đa số trong quốc gia của bạn (tùy theo số nào lớn hơn) để đặt cược hoặc tham gia khuyến mãi. Không đủ tuổi khi đăng nhập sẽ bị vô hiệu. Bạn có thể yêu cầu bất kỳ lúc nào để cung cấp cho công ty, hoặc một bên thứ ba bằng chứng về tuổi và / hoặc nhận dạng. Bạn phải có khả năng để xuất trình hộ chiếu hợp lệ hoặc hình ảnh ID hợp lệ khác để xác minh tuổi của bạn và / hoặc nhận dạng. Bất kỳ cầu thủ không thể cung cấp bằng chứng như vậy tuổi sẽ được loại trừ.",
    },
    {
      id: 4,
      content:
        "Điều này cung cấp tiền thưởng khuyến mãi được giới hạn một thời gian chuộc lại duy nhất cho mỗi người / tài khoản / gia đình / địa chỉ đăng ký hợp pháp / email địa chỉ / số điện thoại / tài khoản thanh toán (ví dụ như thẻ ghi nợ hoặc thẻ tín dụng, Neteller vv ...) / IP Địa chỉ / môi trường máy tính dùng chung, ví dụ như trường học, thư viện công cộng hoặc nơi làm việc. Chúng tôi bảo lưu quyền rút lại sự sẵn có của bất kỳ cung cấp tiền thưởng cho bất kỳ khách hàng hay nhóm khách hàng.",
    },
    {
      id: 5,
      content:
        "Trong thời gian khuyến mãi, các quy tắc nghiêm ngặt về tình trạng lạm dụng nhiều tài khoản sẽ được thực thi. công ty bảo lưu quyền không đủ điều kiện và Khuyến mãi / hoặc trang web bất kỳ người chơi nào bị nghi ngờ có nhiều hơn một tài khoản khuyến mãi. Quyết định những gì hoạt động tạo nên lạm dụng nhiều tài khoản nằm với công ty quản lý nào tuỳ ý.",
    },
    {
      id: 6,
      content:
        "Khuyến khích dành cho người chơi giải trí, những người thích sự may rủi với mục đích giành chiến thắng. Theo quyết định của công ty, chúng tôi có thể xem xét các hoạt động của bạn như chơi gian lận, khi chúng ta có một cơ sở hợp lý để tin rằng các hoạt động của bạn xuất hiện cho chúng tôi là khai thác này cung cấp quảng cáo và / hoặc không bao giờ thể hiện bất kỳ mức độ rủi ro với các quỹ cá nhân hoặc ý định nghiêm túc để chơi.",
    },
    {
      id: 7,
      content:
        "Trong thời gian khuyến mãi, quy định chặt chẽ liên quan đến hành vi của người chơi sẽ được thi hành. công ty có quyền hành động nếu họ tin rằng một người chơi được tham gia vào bất kỳ hoạt động gian lận hoặc bất hợp pháp (bao gồm cả sự tham gia đó sẽ là một hành vi vi phạm trong luật pháp địa phương thẩm quyền) và hành động chống lại bất kỳ người chơi tìm thấy được, lợi dụng bất kỳ khía cạnh nào của Khuyến khích. Hành động như vậy bao gồm, nhưng không giới hạn, loại trừ hoặc tạm đình chỉ các trang web, và bị tịch thu phần thưởng thu được như là một kết quả của việc lạm dụng. Quyết định những gì hoạt động tạo nên lạm dụng chương trình khuyến mãi nằm với công ty quản lý nào tuỳ ý.",
    },
    {
      id: 8,
      content:
        "Gian lận, nhiều hoặc không chính xác hoàn thành mục sẽ không được chấp nhận, không mục được thực hiện vi phạm hoặc không tuân thủ những điều khoản và điều kiện. Trách nhiệm không được chấp nhận cho các mục mà không nhận được bất cứ lý do bởi công ty.",
    },
    {
      id: 9,
      content:
        "Tiền gửi chỉ được chấp thuận việc xác minh cần thiết được hoàn thành sẽ được coi là quyền được tham gia khuyến mãi.",
    },
    {
      id: 10,
      content:
        "Công ty có quyền, bất cứ lúc nào, để thay đổi các điều khoản và điều kiện theo quyết định duy nhất của nó bao gồm Công bỏ, sửa đổi hoặc đình chỉ việc khuyến mãi.",
    },
    {
      id: 11,
      content:
        "Trong trường hợp có sự khác biệt giữa các ý nghĩa của bất kỳ phiên bản dịch về các Điều khoản và Điều kiện khuyến mãi và ngôn ngữ phiên bản tiếng Anh, ý nghĩa của phiên bản ngôn ngữ tiếng Anh sẽ được ưu tiên áp dụng.",
    },
    {
      id: 12,
      content:
        "Bằng cách tham gia chương trình khuyến mãi, bạn đồng ý cho công ty bằng cách sử dụng tên, chân dung và hình ảnh trên trang web và trong các chiến dịch tiếp thị hoặc thay mặt cho công ty trong bất kỳ phương tiện truyền thông nào mà không bồi thường thêm và bạn tuyệt đối từ bỏ bất kỳ khiếu nại chống lại công ty này.",
    },
    {
      id: 13,
      content:
        "Bạn chuyển bằng cách gán bất kỳ và tất cả bản quyền và quyền tài sản khác trí tuệ trong mục nhập của bạn, khuyến khích cho việc sử dụng không hạn chế của công ty và đồng ý để làm tất cả các hành vi như vậy và / hoặc thực hiện hoặc mua các thực hiện của tất cả các tài liệu như vậy trong một hình thức hợp lý thỏa đáng cho công ty để xác nhận của công ty sở hữu các quyền đó.",
    },
    {
      id: 14,
      content:
        'Nhân viên và người thân của các nhân viên công ty và các đơn vị trực thuộc hoặc liên quan đến công ty không được phép tham gia chương trình khuyến mãi. Đối với những mục đích "tương đối" có nghĩa là vợ, chồng, đối tác, cha mẹ, con hoặc anh, chị, em ruột. Sự hạn chế này cũng áp dụng cho các cựu nhân viên và người thân của các nhân viên cũ của công ty (bao gồm bất kỳ các đơn vị trực thuộc hoặc liên quan đến công ty) cho một khoảng thời gian sáu (6) tháng từ ngày cuối cùng của mình / việc làm của mình với công ty (bao gồm bất kỳ đối tượng liên kết hoặc liên quan đến công ty).',
    },
    {
      id: 15,
      content:
        "Bằng cách tham gia chương trình khuyến mãi, mỗi người đăng ký đồng ý để phát hành, xả và tổ chức công ty vô hại, người đại diện hợp pháp của họ, các chi nhánh, công ty con, các cơ quan và cán bộ tương ứng, giám đốc, nhân viên và đại lý từ bất kỳ thiệt hại hoặc tổn thất hoặc duy trì trong kết nối với việc chấp nhận bất kỳ giải thưởng và / hoặc tham gia chương trình khuyến mãi.",
    },
    {
      id: 16,
      content:
        "Bằng cách tham gia chương trình khuyến mãi, bạn xác nhận rằng tên và địa chỉ được đăng ký trong tài khoản của bạn là chính xác và cập nhật. Nếu không cung cấp thông tin này có thể dẫn đến loại quyền thi đấu từ khuyến mãi.",
    },
    {
      id: 17,
      content:
        "Tất cả các người chơi tham gia chương trình khuyến mãi phải tuân thủ tất cả các quy tắc của công ty, cũng như các Điều khoản và Điều kiện được liệt kê trên trang web.",
    },
    {
      id: 18,
      content:
        "Trong trường hợp có sự vi phạm các điều khoản và điều kiện, công ty có quyền hành động như vậy, nếu xét thấy thích hợp trong quyết định tuyệt đối của công ty.",
    },
    {
      id: 19,
      content:
        "Quyết định của công ty đối với bất kỳ vấn đề nào liên quan đến hoặc có liên quan đến cược chơi và các chương trình khuyến mãi cuối cùng và không có sự tương ứng sẽ được nhập vào.",
    },
    {
      id: 20,
      content:
        "Trong trường hợp các tranh chấp liên quan đến các quyền tham gia của người chơi trong chương trình khuyến mãi, tất cả các vấn đề liên quan đến việc trao giải thưởng của các tiền thưởng và / hoặc các điều kiện, quyết định cuối cùng thuộc vào quản lý của công ty. Quyết định sẽ được ràng buộc và sẽ không là đối tượng để xem xét hoặc khiếu nại bởi bất kỳ người chơi hoặc bên thứ ba.【 Quy tắc và điều kiện khuyến mãi 】Chú ý : 1 Điểm = 1000 VND. Thời gian chuẩn:GMT+8",
    },
    {
      id: 21,
      content:
        "Mỗi một thành viên, mỗi tài khoản, mỗi địa chỉ email, mỗi số điện thoại, cùng một phương thức thanh toán (cùng thẻ ghi nợ / thẻ tín dụng / tài khoản ngân hàng) và địa chỉ IP chỉ được tham gia một lần khuyến mãi: Nếu thành viên cố tình tạo nhiều tài khoản lạm dụng khuyến mãi, Giải trí OK9 có quyền hủy bỏ hoặc thu hồi số tiền thưởng và lợi nhuận của thành viên.",
    },
    {
      id: 22,
      content:
        "Tất cả các chương trình khuyến mãi tại Giải trí OK9 đều dựa vào nhu cầu thực tế của người chơi. Nếu bất kỳ nhóm hoặc cá nhân nào có hành vi lạm dung hoặc không trung thực khi tham gia khuyến mãi lạm dụng khuyến mãi từ Giải trí OK9, v.v... Giải trí OK9 có quyền hủy bỏ tài khoản của một nhóm hoặc cá nhân và đóng băng số dư tài khoản khi vi phạm.",
    },
    {
      id: 23,
      content:
        "Nếu thành viên có tranh chấp về sự kiện khuyến mãi này, để đảm bảo quyền lợi của cả hai bên và tránh ảnh hưởng uy tín, Giải trí OK9 có quyền yêu cầu thành viên cung cấp cho chúng tôi các tài liệu liên quan và có hiệu lực để xác nhận xem thành viên có đủ điều kiện nhận khuyến mãi này hay không.",
    },
    {
      id: 24,
      content:
        "Khi thành viên tham gia không tuân thủ đầy đủ hoặc vi phạm quy định,lạm dụng bất kỳ điều khoản nào về chiết khấu hoặc khuyến mãi của Giải trí OK9, Giải trí OK9 phát hiện bất kỳ cá nhân hoặc nhóm thành viên nào có hành vi cược bất thường liên quan. Tiền thưởng khi gửi tiền hoặc các hoạt động khuyến mãi khác mang lại lợi nhuận, Giải trí OK9 có quyền dừng, hủy bỏ khuyến mãi hoặc thu hồi lại tất cả tiền thưởng đã được kết toán cho đội hoặc cá nhân này. Ngoài ra, Giải trí OK9 cũng có quyền trích các khoản chi phí quản lý tương đương với giá trị tiền thưởng khuyến mãi từ những khách hàng này để bù đắp cho chi phí quản lý của chúng tôi.",
    },
    {
      id: 25,
      content:
        "Xổ số 2D cược tối đa 70 số, 3D cược tối đa 600 số, 4D cược tối đa 5500 số,vượt quá hệ thống tự động thu hồi tiền thắng phát sinh.",
    },
    {
      id: 26,
      content:
        "Tất cả cược hòa, cược hủy, cược vô hiệu, cược đặt 2 bên, cược kiểu HK tỷ lệ dưới 0.6 (tương tự cho các kiểu cược khác như Malay odd dưới 0.6 ; Indo odd dưới-2.00 ; US odd dưới -200 và Dec odd dưới 1.60) nhận khuyến mãi sẽ không được tính là hợp lệ.",
    },
    {
      id: 27,
      content:
        "Các kiểu đánh đối đầu,roulette vượt quá 30 số ...được xem là vi phạm.",
    },
    {
      id: 28,
      content:
        "Thành viên lạm dụng, vi phạm quy định, những vé cược bị công ty từ chối không được tham gia khuyến mãi.",
    },
    {
      id: 29,
      content:
        "Giải trí OK9 có quyền quyết định cuối cùng về sự kiện và quyền sửa đổi và chấm dứt sự kiện mà không cần thông báo, áp dụng cho tất cả các khuyến mãi.",
    },
  ];

  return (
    <>
      <Modal
        title="Điều khoản và điều kiện tham gia tại OK9"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p className="p-4 text-white text-[0.9rem]">
          Trang này chứa thông tin các quyền của bạn trong việc sử dụng trang
          web của công ty. Điều khoản và điều kiện liên quan đến các trò chơi và
          chương trình khuyến mãi có sẵn trên trang web được đưa vào đây để tham
          khảo.
        </p>
        <ol>
          {data.map((item, index) => (
            <li key={index} className="text-white">
              {item.content}
            </li>
          ))}
        </ol>
      </Modal>
    </>
  );
}

export default ModalPolicy;
